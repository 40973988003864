.container {
    display: flex;
    flex-direction: column;
}

.page-container {
    padding: 20px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.left-nav {
    flex: 0.8; /* Take up 1 part of the space */
}

.main-content {
    flex: 3; /* Take up 3 parts of the space */
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
