.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.radioGroupContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
