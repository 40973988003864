.container {
    width: 50%;
    margin: auto;
    text-align: center;
}

.inputField {
    margin: 20px 0;
}

.inputField label {
    display: block;
    font-size: 1.2em;
}

.inputField input {
    width: 70%;
    padding: 10px;
    font-size: 1em;
}

.button {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 1.2em;
}

.error {
    color: red;
    font-weight: bold;
}
